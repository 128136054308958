"use client";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import * as React from "react";

import { cn } from "../utils";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    variant?: string;
    label?: React.ReactNode;
    isValid?: string;
  }
>(({ className, variant = "default", isValid = "true", ...props }, ref) => {
  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        "group peer relative disabled:cursor-not-allowed disabled:opacity-50",
        variant === "default"
          ? "size-6"
          : cn(
              "pointer-events-none size-4 appearance-none",
              "before:absolute before:left-0 before:top-1/2 before:size-[18px] before:-translate-y-1/2 before:rounded-[3px] before:border before:border-[var(--color-glo-gray-3)] before:bg-white before:content-[''] hover:before:border-black"
            ),
        variant === "primary" && isValid === "false" ? "before:border-[var(--text-glo-error)]" : "",
        className
      )}
      {...props}
    >
      {variant === "default" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="size-full fill-secondary group-aria-checked:fill-primary"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" />
        </svg>
      )}
      <CheckboxPrimitive.Indicator
        className={cn("absolute", variant === "default" ? "left-1 top-[4px]" : "left-0.5 top-0.5")}
      >
        <Check
          className={cn(variant === "default" ? "size-4" : "size-[13px] cursor-pointer select-none leading-none")}
        />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
